.main {
    display: grid;
    grid-auto-rows: repeat(min-height);
    gap: 3em;
    overflow-x: hidden;
}

.create_with_cademy_container {
    overflow: hidden;
}

.section,
.shaded_section {
    padding-block-end: 100px;
    padding-block-start: 20px;
    position: relative;
    overflow: hidden;
}

.shaded_section {
    background-color: var(--bg-1);
}

.featuresCarouselHeading {
    margin-block-end: 0;
}

.blueText {
    color: var(--cademy-brand-color);
}

@media screen and (max-width: 800px) {
    .main {
        margin: 0;
    }
}
