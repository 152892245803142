.wrapper {
    background: rgb(255, 255, 255);
    background: linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(61, 86, 224, 0.06) 100%);
    position: relative;
    overflow: hidden;
    height: 500px;

    .contents {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 500px;
        text-align: center;
        text-wrap: balance;

        .headline > h1 {
            font-size: 2.5rem;
            display: inline;
        }

        h3 {
            font-weight: var(--font-weight-bolder);
        }

        .ctaButton {
            display: inline-block;
            font-weight: var(--font-weight-bold);
            font-size: var(--font-size-h3);
            height: unset;
            padding-block: var(--padding-tiny);
            padding-inline: 2.5em;
            border-radius: 90px;
        }
    }

    .carousel {
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin: 0 2rem;

        div:nth-child(1) {
            transform: translateX(5px) rotate(-2deg);
        }

        div:nth-child(2) {
            transform: translateX(-10px) rotate(3deg);
        }

        div:nth-child(3) {
            transform: translateX(8px) rotate(-1deg);
        }

        div:nth-child(4) {
            transform: translateX(-5px) rotate(2deg);
        }

        div:nth-child(5) {
            transform: translateX(3px) rotate(-3deg);
        }

        div:nth-child(6) {
            transform: translateX(-8px) rotate(1deg);
        }

        div:nth-child(7) {
            transform: translateX(6px) rotate(-2deg);
        }

        div:nth-child(8) {
            transform: translateX(-12px) rotate(3deg);
        }

        div:nth-child(9) {
            transform: translateX(9px) rotate(-1deg);
        }

        div:nth-child(10) {
            transform: translateX(-7px) rotate(2deg);
        }

        div:nth-child(11) {
            transform: translateX(4px) rotate(-3deg);
        }

        div:nth-child(12) {
            transform: translateX(-9px) rotate(1deg);
        }

        div:nth-child(13) {
            transform: translateX(7px) rotate(-2deg);
        }

        div:nth-child(14) {
            transform: translateX(-11px) rotate(3deg);
        }

        div:nth-child(15) {
            transform: translateX(10px) rotate(-1deg);
        }

        div:nth-child(16) {
            transform: translateX(-6px) rotate(2deg);
        }
    }

    .carouselLeft {
        top: 0;
        left: 12%;
        animation: verticalScroll 180s infinite alternate linear;
    }

    .carouselRight {
        top: 0;
        right: 12%;
        animation: verticalScroll 180s infinite alternate-reverse linear;
    }
}

@keyframes verticalScroll {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-180rem);
    }
}

@media screen and (max-width: 1500px) {
    .wrapper {
        .carouselLeft {
            left: 2%;
        }

        .carouselRight {
            right: 2%;
        }
    }
}

@media screen and (max-width: 1050px) {
    .wrapper {
        height: 650px;

        .contents {
            justify-content: flex-start;
            margin-block: var(--margin-giant);

            .headline > h1 {
                font-size: 2.5rem;
                line-height: 2rem;
                display: block;
            }
        }
        .carousel {
            flex-direction: row;
        }

        .carouselLeft {
            top: unset;
            bottom: var(--padding);
            left: 0;
            right: 0;
            animation: horizontalScroll 180s infinite alternate linear;
        }

        .carouselRight {
            display: none;
        }

        @keyframes horizontalScroll {
            0% {
                transform: translateX(0);
            }

            100% {
                transform: translateX(-180rem);
            }
        }
    }
}

@media (prefers-reduced-motion) {
    .carouselLeft,
    .carouselRight {
        animation: unset;
    }
}
