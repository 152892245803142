.card {
    background-color: var(--bg-1);
    display: grid;
    justify-items: start;
    align-items: flex-end;
    height: 16em;
    width: 12.5em;
    flex: 0 0 12.5em;
    position: relative;
    scroll-snap-align: start;
    background-color: transparent;

    &_image {
        position: relative;
        height: 100%;
        width: 100%;

        img {
            object-fit: cover;
            filter: brightness(60%);
            transition: all 0.3s ease-in-out;
            border-radius: 12px;

            &:hover {
                filter: brightness(90%);
            }
        }
    }

    h3 {
        position: absolute;
        color: var(--white);
        margin-inline-start: 1em;
    }
}

.heading {
    margin-block-start: 2em;

    h2 {
        margin-block-end: 0;
        display: inline;

        font-size: 2rem;
        line-height: 3rem;
        letter-spacing: 0px;
        font-weight: 700;
        text-align: center;
    }
}

.wrapper {
    text-align: center;
}

.cta_container {
    display: flex;
    justify-content: center;
    gap: 1em;
    margin-top: 1em;
}

.carousel_container {
    display: flex;
    gap: 1em;
    margin-block-start: 2em;
    padding-block-start: 0.5rem;
    padding-block-end: 0.5rem;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;

    background-image: 
        /* Shadow effect on edges of carousel */
        /* Shadows */
        linear-gradient(to right, var(--bg-1), var(--bg-1)),
        linear-gradient(to right, var(--bg-1), var(--bg-1)),
        /* Shadow covers */ linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)),
        linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0));

    background-position:
        left center,
        right center,
        left center,
        right center;
    background-repeat: no-repeat;
    background-color: var(--bg-1);
    background-size:
        20px 100%,
        20px 100%,
        10px 100%,
        10px 100%;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
}

@media screen and (min-width: 351px) and (max-width: 600px) {
    .card {
        height: 16em;
        width: 16em;
    }
}

@media screen and (max-width: 450px) {
    .heading {
        h2 {
            display: block;
            margin-block-start: 0;
        }
    }
}

@media screen and (max-width: 350px) {
    .card {
        height: 10em;
        width: 10em;
    }
}
