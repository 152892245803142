.card {
    position: relative;

    .image {
        width: 200px;
        height: 200px;
        border-radius: var(--rounded-corners-pronounced);
        box-shadow: var(--card-box-shadow);
    }
    .label {
        color: var(--text);
        margin-block-end: var(--margin-big);
        background-color: var(--white);
        border-radius: var(--rounded-corners-pill);
        padding-inline: 10px;
        padding-block: 5px;
        position: absolute;
        bottom: 0;
        left: 10px;
    }
}
